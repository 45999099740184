import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";

export interface EditAverageBillingFormValues {
  averageBilling: boolean;
  averageBillingMonthlyCharge: string;
}
export const editAverageBillingFormValidators =
  generateValidationErrorCollector<EditAverageBillingFormValues>({
    averageBillingMonthlyCharge: [isRequired],
  });
