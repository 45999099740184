import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";

const BASE_PATH = "/api/rhapsody";

export const rhapsodyOfferSnapshotsApi = {
  list: (options: OfferSnapshotSearchOptions) => {
    return ajax.get<PaginationEndpointResponseType<OfferSnapshotType>>(
      `${BASE_PATH}/offer-snapshot/`,
      {
        params: options,
      }
    );
  },
  retrieve: (id: IdType) => {
    return ajax.get<OfferSnapshotType>(`${BASE_PATH}/offer-snapshot/${id}/`);
  },
};
