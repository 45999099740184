import {
  BoButtonTab,
  BoButtonTabContainer,
} from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import {
  CustomerTabType,
  customerTabs,
} from "@ops-utils/types/customerDashboardTypes";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const tabNameMapping = {
  "#add-ons": "Add-Ons",
  "#admin": "Admin",
  "#billing": "Billing",
  "#customer": "Customer",
  "#devices": "Devices",
  "#operations": "Operations",
  "#retention": "Retention",
  "#rewards": "Rewards",
  "#usage": "Usage",
};

export const CustomerDashboardTabs = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleClick = (tab: CustomerTabType) => {
    navigate(tab);
  };

  return (
    <BoButtonTabContainer>
      {customerTabs.map((tabName) => {
        const mappedName = tabNameMapping[tabName];

        return (
          <BoButtonTab
            key={tabName}
            data-active={hash === tabName}
            onClick={() => handleClick(tabName)}
          >
            {mappedName}
          </BoButtonTab>
        );
      })}
    </BoButtonTabContainer>
  );
};
