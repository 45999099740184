import { api } from "@common/api/api";
import { CreateCustomerWithProductsRequest } from "@common/api/apiRequests";
import {
  Validations,
  generateValidationErrorCollector,
} from "@common/forms/validationErrorCollector";
import {
  Validator,
  isPaymentMethodRequired,
  isRequired,
  isValidAdultBirthday,
  isValidDateTodayOrFuture,
  isValidEmail,
  isValidPhoneNumber,
  isValidUSAddress,
} from "@common/forms/validators";
import { CreditCheckResultType } from "@common/types/creditCheckTypes";
import { CustomerType } from "@common/types/customerTypes";
import { FormError } from "@common/types/errorTypes";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import { creditCheckResult } from "@ops/components/ProspectEditor/shared/creditCheck.helpers";
import isEmpty from "lodash/isEmpty";

const isValidCreditCheck: Validator<never, Prospect> = (_value, prospect) => {
  if (!prospect) {
    throw new Error(
      "Prospect is required when running 'isValidCreditCheck' - ensure that you are properly passing in a prospect"
    );
  }

  const messages: Record<string, string> = {
    [CreditCheckResultType.ssnRequired]:
      "Customer must provide their social security number to complete the credit check.",
    [CreditCheckResultType.idRequired]:
      "Customer must send a copy of their government issued photo ID to support@gotrhythm.com.",
    [CreditCheckResultType.noCreditCheck]: "Credit check is required.",
    [CreditCheckResultType.insufficientData]:
      "The credit check needs more information before it is complete.",
  };
  const result = creditCheckResult(prospect);

  return messages[result];
};

const isGovernmentIdRequested: Validator<never, Prospect> = (
  _value,
  prospect
) => {
  if (!prospect) {
    throw new Error(
      "Prospect is required when running 'governmentIdRequested' - ensure that you are properly passing in a prospect"
    );
  }

  if (
    prospect.governmentIdRequested &&
    prospect.needsManualReview &&
    !prospect.bypassedCreditCheck
  ) {
    return "Customer must send a copy of their government issued photo ID to support@gotrhythm.com.";
  }
};

const buildValidations = ({ isCSR = true }) => {
  const validations: Validations<Prospect> = {
    billingPaymentMethodId: [isPaymentMethodRequired],
    creditCheckSummary: [isValidCreditCheck],
    dateOfBirth: [isRequired, isValidAdultBirthday],
    email: [isRequired, isValidEmail],
    enrollmentType: [isRequired],
    esiId: [isRequired],
    firstName: [isRequired],
    governmentIdRequested: [],
    languagePreference: [isRequired],
    lastName: [isRequired],
    offersnapshotId: [isRequired],
    phone: [isRequired, isValidPhoneNumber],
    serviceAddress: isValidUSAddress,
    serviceStartDate: [isRequired, isValidDateTodayOrFuture],
  };

  if (isCSR) {
    validations.governmentIdRequested = [isGovernmentIdRequested];
    validations.creditCheckSummary = [
      isValidCreditCheck,
      isGovernmentIdRequested,
    ];
  }
  return validations;
};

const FIELDS_BY_SECTION: Record<ConfirmationSection, string[]> = {
  [ConfirmationSection.billing]: [
    "billingPaymentMethodId",
    "creditCheckSummary",
    "enrollmentType",
    "governmentIdRequested",
    "serviceStartDate",
  ],
  [ConfirmationSection.contactInfo]: [
    "dateOfBirth",
    "email",
    "firstName",
    "languagePreference",
    "lastName",
    "phone",
  ],
  [ConfirmationSection.marketing]: [],
  [ConfirmationSection.plan]: ["offersnapshotId"],
  [ConfirmationSection.productAddons]: [],
  [ConfirmationSection.serviceAddress]: ["serviceAddress", "esiId"],
};

export class ProspectSubmission {
  private readonly prospect: Prospect;
  public errorMessages?: Partial<
    Record<ConfirmationSection, Record<string, string[] | FormError>>
  >;
  private readonly validator;

  constructor(prospect: Prospect, { isOps = false }: { isOps?: boolean }) {
    this.prospect = prospect;
    this.validator = generateValidationErrorCollector<Prospect>(
      buildValidations({ isCSR: !isOps })
    );
  }

  get isValid(): boolean {
    const errors = this.validator(this.prospect);

    this.errorMessages = {};

    Object.entries(FIELDS_BY_SECTION).forEach(([section, fields]) => {
      fields.forEach((field) => {
        if (!isEmpty(errors[field])) {
          if (this.errorMessages) {
            (this.errorMessages[section as ConfirmationSection] ||= {})[field] =
              errors[field];
          }
        }
      });
    });
    return isEmpty(this.errorMessages);
  }

  get errors() {
    if (this.errorMessages) {
      return (Object.keys(this.errorMessages) as ConfirmationSection[]).reduce(
        (result, sectionKey) => {
          const section = (this.errorMessages ?? {})[sectionKey];

          if (!section) {
            return result;
          }

          Object.keys(section).forEach((field) => {
            // eslint-disable-next-line no-param-reassign
            (result[sectionKey] ||= {})[field] = true;
          });

          return result;
        },
        {} as Record<ConfirmationSection, Record<string, boolean>>
      );
    }

    throw Error(
      "Must validate data by running isValid() before calling errors"
    );
  }

  get signUpData(): CreateCustomerWithProductsRequest {
    const {
      dateOfBirth,
      email,
      enrollmentType,
      esiId,
      firstName,
      id,
      languagePreference,
      lastName,
      marketingEmailOptIn,
      offersnapshotId,
      phone,
      referralCode,
      serviceAddress,
      serviceStartDate,
      productPrices,
    } = this.prospect;

    // We know that all of this data is present since `isValid` will not let us proceed without the required data
    // There is no need for a giant guard clause here since we're smarter than typescript
    /* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/ban-ts-comment */
    return {
      customerData: {
        csrSignUp: true,
        // @ts-ignore
        dateOfBirth,
        // @ts-ignore
        email,
        enrollmentType: enrollmentType!,
        esiId,
        // @ts-ignore
        firstName,
        languagePreference: languagePreference ?? "en",
        // @ts-ignore
        lastName,
        // @ts-ignore
        offersnapshotId,
        // @ts-ignore
        phone,
        productPrices,
        prospectId: id,

        referralFromFriendCode: referralCode,
        // @ts-ignore
        sendMarketingPromos: marketingEmailOptIn ?? false,

        serviceAddress: {
          addressLine1: serviceAddress.addressLine1,
          city: serviceAddress.city,
          state: serviceAddress.state,
          unitNumber: serviceAddress.unitNumber || "",
          zipCode: serviceAddress.zipCode,
        },
        // @ts-ignore
        serviceStartDate,
      },
    };
    /* eslint-enable */
  }

  submit(successDelay: number = 5000) {
    if (!this.isValid) {
      throw Error("Sign up data is not valid. Customer could not be created.");
    }

    return api.customers.create(this.signUpData).then(
      (resp) =>
        new Promise((resolve: (resp: CustomerType) => void) => {
          // we need to slow down the front-end to ensure that the create customer background tasks
          // (primarily zuora) have time to complete
          setTimeout(() => resolve(resp), successDelay);
        })
    );
  }
}
