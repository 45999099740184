import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { legacyContactLogApi } from "@ops-data/api/legacyContactLogApi";
import { BoCheckboxField } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import {
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { BoTextAreaField } from "@ops-design-system/components/BoTextAreaField/BoTextAreaField";
import {
  ContactLogCategoryChoice,
  ContactMethodOptions,
  CreateContactLogType,
} from "@ops-utils/types/contactLogTypes";
import {
  StyledAddNewButton,
  StyledFormBody,
} from "@ops/components/SupportLogs/ContactLog/AddContactLog/AddContactLog.styled";
import { ContactLogService } from "@ops/components/SupportLogs/ContactLog/ContactLogService";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import React, { useEffect } from "react";
import { Form } from "react-final-form";

interface ContactLogOptionsType {
  categories: BoSelectOptionType[];
  reasons: Record<IdType, BoSelectOptionType[]>;
  types: Record<IdType, BoSelectOptionType[]>;
}

export interface AddContactLogFormValues {
  category?: BoSelectValue;
  contactMethod: BoSelectValue;
  notes: string;
  outboundContact: boolean;
  reason?: BoSelectValue;
  type?: BoSelectValue;
}

const parseContactLogOptions = (response: ContactLogCategoryChoice[]) => {
  const contactLogOptions: ContactLogOptionsType = {
    categories: [],
    reasons: {},
    types: {},
  };

  contactLogOptions.categories = response.map((category) => {
    contactLogOptions.types[category.id] = category.types.map((type) => {
      contactLogOptions.reasons[type.id] = type.reasons.map((reason) => ({
        label: reason.value,
        value: reason.id,
      }));

      return {
        label: type.value,
        value: type.id,
      };
    });

    return { label: category.value, value: category.id };
  });

  return contactLogOptions;
};

const addContactLogFormValidators =
  generateValidationErrorCollector<AddContactLogFormValues>({
    category: [isRequired],
    contactMethod: [isRequired],
    notes: [isRequired],
    type: [isRequired],
  });

interface AddContactLogProps {
  onAdd: () => Promise<void>;
}
export const AddContactLog = ({ onAdd }: AddContactLogProps) => {
  const { target } = useSupportLogsContext();
  const contactLogService = new ContactLogService(target.type);
  const flash = useRhFlash();
  const [
    { requestMonitor: submitRequestMonitor },
    {
      setSuccess: setSubmitSuccess,
      setFailure: setSubmitFailure,
      setPending: setSubmitPending,
    },
  ] = useAjaxState();
  const [
    { requestMonitor: fetchCategoriesMonitor, data: contactLogOptions },
    {
      setSuccess: setFetchCategoriesSuccess,
      setFailure: setFetchCategoriesFailure,
      setPending: setFetchCategoriesPending,
    },
  ] = useAjaxState<ContactLogOptionsType>();
  const { open, close, isOpen } = useModalState();
  const handleSubmit = (formValues: AddContactLogFormValues) => {
    setSubmitPending();
    const contactLog: CreateContactLogType = {
      category: `${formValues.category}`,
      contactMethod: `${formValues.contactMethod}`,
      notes: formValues.notes,
      outboundContact: formValues.outboundContact,
      reason: undefined,
      type: `${formValues.type}`,
    };

    if (formValues.reason) {
      contactLog.reason = `${formValues.reason}`;
    }
    return contactLogService
      .create(target.id, contactLog)
      .then(() => {
        setSubmitSuccess();
        onAdd().then(close);
      })
      .catch((error: RhApiError) => {
        flash.error("Unable to save contact log.");
        setSubmitFailure(error);
      });
  };

  useEffect(() => {
    setFetchCategoriesPending();
    legacyContactLogApi.categories
      .list()
      .then((response) => {
        setFetchCategoriesSuccess(parseContactLogOptions(response));
      })
      .catch((error: RhApiError) => {
        flash.error("Unable to get categories, types, or reasons");
        setFetchCategoriesFailure(error);
      });
  }, []);

  /*  Note: possibly revisit to fetch methods from backend */
  const contactMethodOptions = Object.values(ContactMethodOptions).map(
    (method) => ({
      label: method,
      value: method,
    })
  );

  return (
    <>
      <StyledAddNewButton onClick={open}>+ Add New</StyledAddNewButton>

      <BoModal open={isOpen} onClose={close}>
        <Form<AddContactLogFormValues>
          onSubmit={handleSubmit}
          validate={addContactLogFormValidators}
          validateOnSubmit
          initialValues={{ outboundContact: false }}
          render={({ handleSubmit: handleFormSubmit, values }) => {
            return (
              <form noValidate onSubmit={handleFormSubmit}>
                <BoDialogHeader>Add Contact Log</BoDialogHeader>
                <BoDialogBody>
                  {fetchCategoriesMonitor.isPending || !contactLogOptions ? (
                    <BoCircularProgress />
                  ) : (
                    <StyledFormBody>
                      <BoSelectField
                        name="contactMethod"
                        label="Contact Method"
                        requiredIndicator
                        options={contactMethodOptions}
                      />
                      <BoSelectField
                        name="category"
                        label="Category"
                        requiredIndicator
                        options={contactLogOptions.categories}
                        value={values.category}
                      />
                      <BoSelectField
                        name="type"
                        label="Type"
                        requiredIndicator
                        options={
                          contactLogOptions.types[values.category ?? ""] || []
                        }
                        value={values.type}
                      />
                      <BoSelectField
                        name="reason"
                        label="Reason"
                        options={
                          contactLogOptions.reasons[values.type ?? ""] || []
                        }
                        value={values.reason}
                      />
                      <BoTextAreaField name="notes" requiredIndicator>
                        Notes
                      </BoTextAreaField>
                      <BoCheckboxField
                        label="Outbound Contact"
                        name="outboundContact"
                      />
                    </StyledFormBody>
                  )}
                </BoDialogBody>
                <BoDialogButtonFooter
                  confirmDisabled={submitRequestMonitor.isPending}
                  confirmBtnText="Add Contact Log"
                  confirmBtnType="submit"
                  cancelBtnText="Close"
                  onCancel={close}
                />
              </form>
            );
          }}
        />
      </BoModal>
    </>
  );
};
