import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { ReactComponent as RhapsodyAvatar } from "@ops-design-system/images/RhapsodyAvatar.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CustomerTabType } from "@ops-utils/types/customerDashboardTypes";
import { QueryParams } from "@ops-utils/types/queryParamsTypes";
import { AddOnsTab } from "@ops/components/CustomerDashboard/AddOnsTab/AddOnsTab";
import { AdminTab } from "@ops/components/CustomerDashboard/AdminTab/AdminTab";
import { BillingTab } from "@ops/components/CustomerDashboard/BillingTab/BillingTab";
import { CustomerTab } from "@ops/components/CustomerDashboard/CustomerTab/CustomerTab";
import { DevicesTab } from "@ops/components/CustomerDashboard/DevicesTab/DevicesTab";
import { NewEnrollmentModal } from "@ops/components/CustomerDashboard/NewEnrollmentModal";
import { OperationsTab } from "@ops/components/CustomerDashboard/OperationsTab/OperationsTab";
import { RetentionTab } from "@ops/components/CustomerDashboard/RetentionTab/RetentionTab";
import { RewardsTab } from "@ops/components/CustomerDashboard/RewardsTab/RewardsTab";
import { UsageTab } from "@ops/components/CustomerDashboard/UsageTab/UsageTab";
import { CustomerDashboardTabs } from "@ops/components/CustomerDashboardTabs/CustomerDashboardTabs";
import { CustomerInfoCard } from "@ops/components/CustomerInfoCard/CustomerInfoCard";
import { PremiseSelectorCard } from "@ops/components/PremiseSelectorCard/PremiseSelectorCard";
import { PremiseStatusIndicatorBar } from "@ops/components/PremiseStatusIndicatorBar/PremiseStatusIndicatorBar";
import { SupportLogs } from "@ops/components/SupportLogs/SupportLogs";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";
import { useCustomer } from "@ops/hooks/useCustomer";
import { customerCancel, customerClear } from "@ops/slices/customerSlice";
import { paymentMethodsClear } from "@ops/slices/paymentMethodsSlice";
import { premiseClear } from "@ops/slices/premiseSlice";
import { premiseStatusIndicatorsClear } from "@ops/slices/premiseStatusIndicatorsSlice";
import React, { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";

const tabComponents: Record<CustomerTabType, JSX.Element> = {
  "#add-ons": <AddOnsTab />,
  "#admin": <AdminTab />,
  "#billing": <BillingTab />,
  "#customer": <CustomerTab />,
  "#devices": <DevicesTab />,
  "#operations": <OperationsTab />,
  "#retention": <RetentionTab />,
  "#rewards": <RewardsTab />,
  "#usage": <UsageTab />,
};

const AvatarContainer = styled.span`
  height: 48px;
  margin-right: ${rhOpsSpacingPx(2)};
  width: 48px;
`;

const CustomerHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CustomerDashboard = () => {
  const { id } = useParams<{ id: IdType }>();
  const { customer, requestMonitor } = useCustomer(id);
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { close, isOpen } = useModalState(true);
  const [params] = useSearchParams();
  const { pathname, hash } = useLocation();

  const closeNewEnrollmentModal = () => {
    close();
    navigate({
      hash,
      pathname,
    });
  };

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(customerCancel());
        dispatch(customerClear());
        dispatch(premiseClear());
        dispatch(premiseStatusIndicatorsClear());
        dispatch(paymentMethodsClear());
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hash) {
      navigate("#customer");
    }
  }, [hash, navigate]);

  if (requestMonitor.isWaiting && !customer) {
    return <BoCircularProgress />;
  }

  if (requestMonitor.didFail || !customer) {
    flash.error(
      "We're having trouble getting this customer's data. Please try again."
    );

    return null;
  }

  return (
    <BoGrid $variant={GridVariants.Grid2_1}>
      <BoGridColumn>
        <CustomerHeaderContainer>
          <AvatarContainer>
            <RhapsodyAvatar />
          </AvatarContainer>
          <CustomerInfoCard customer={customer} />
        </CustomerHeaderContainer>
        <PremiseSelectorCard />
        <PremiseStatusIndicatorBar />
        <CustomerDashboardTabs />
        {tabComponents[hash as CustomerTabType]}
      </BoGridColumn>
      <BoGridColumn>
        <SupportLogs target={{ id: customer.id, type: TargetType.CUSTOMER }} />
      </BoGridColumn>

      {Boolean(params.get(QueryParams.CONFIRMATION_NUMBER)) && (
        <NewEnrollmentModal
          open={isOpen}
          onClose={closeNewEnrollmentModal}
          confirmationNumber={
            params.get(QueryParams.CONFIRMATION_NUMBER) as string
          }
          name={customer.fullName}
        />
      )}
    </BoGrid>
  );
};
