import { api } from "@common/api/api";
import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as Extension } from "@ops-design-system/icons/Extension.svg";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { termsAgreement } from "@ops/constants/contractTerms.constants";
import { ContractSwapType } from "@ops/constants/retention.constants";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import React, { FC, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface OfferLookupFormValues {
  offerId: IdType;
}

const StyledLabel = styled<typeof BoLabel>(Body3).attrs({ as: "label" })``;

const TermsAgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${rhOpsSpacingPx(-1)};
  margin-top: ${rhOpsSpacingPx(4.5)};
`;

interface ResultsContentProps {
  confirmDisabled: boolean;
  offerSnapshot: OfferSnapshot;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmSwapContent = ({
  offerSnapshot,
  onClose,
  confirmDisabled,
  onSubmit,
}: ResultsContentProps) => {
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const handleCheckboxChange = () => {
    setAgreedToTerms((prevAgreedToTerms) => !prevAgreedToTerms);
  };

  return (
    <>
      <BoDialogHeader>Confirm Contract Swap</BoDialogHeader>
      <BoDialogBody>
        <OfferCard key={offerSnapshot.id} offerSnapshot={offerSnapshot} />
        <TermsAgreementContainer>
          <BoCheckboxInput
            id="termsAgreement"
            onChange={handleCheckboxChange}
            name="Terms Agreement"
          />
          <StyledLabel htmlFor="termsAgreement">{termsAgreement}</StyledLabel>
        </TermsAgreementContainer>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Confirm Swap"
        confirmBtnType="button"
        cancelBtnText="Cancel"
        confirmDisabled={confirmDisabled || !agreedToTerms}
        onCancel={onClose}
        onConfirm={onSubmit}
      />
    </>
  );
};

interface SearchContentProps {
  onClose: () => void;
  onSubmit: (formValues: OfferLookupFormValues) => void;
}
const SearchContent: FC<React.PropsWithChildren<SearchContentProps>> = ({
  onSubmit,
  onClose,
}) => {
  return (
    <>
      <BoDialogHeader>Look Up OfferSnapshot</BoDialogHeader>
      <Form<OfferLookupFormValues>
        initialValues={{
          offerId: "",
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit: formHandleSubmit }) => (
          <form noValidate onSubmit={formHandleSubmit}>
            <BoDialogBody>
              <BoTextField name="offerId" placeholder="Snapshot ID">
                Snapshot ID
              </BoTextField>
            </BoDialogBody>
            <BoDialogButtonFooter
              confirmBtnText="Look Up"
              confirmBtnType="submit"
              cancelBtnText="Cancel"
              onCancel={onClose}
            />
          </form>
        )}
      />
    </>
  );
};

export const OfferLookup: FC<React.PropsWithChildren<unknown>> = () => {
  const { open, close, isOpen } = useModalState();

  const { premiseId } = useParams<{ premiseId: IdType }>();

  const [offerSnapshot, setOffer] = useState<OfferSnapshot | null>(null);
  const flash = useRhFlash();
  const { premise } = usePremise(premiseId);
  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const dispatch = useDispatch();

  const onSearchSubmit = (formValues: OfferLookupFormValues) => {
    api.pricing.offerSnapshots
      .retrieve(formValues.offerId)
      .then((offerSnapshotInstance) => {
        setOffer(offerSnapshotInstance);
      })
      .catch(() => {
        flash.error("Unable to retrieve offerSnapshot");
      });
  };

  const onConfirmSubmit = () => {
    if (!premise || !offerSnapshot) {
      flash.error(
        "Cannot swap contract without a valid premise and offerSnapshot"
      );
      return;
    }
    setPending();

    premiseApi.contracts
      .swap(premise.id, offerSnapshot.id, ContractSwapType.OPS)
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleError = () => {
    setFailure();
    flash.error("Issue updating contract for this premise. Please try again.");
    close();
  };

  const handleSuccess = () => {
    setSuccess();
    flash.success("Contract swap for premise successful");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(premiseRefetch(premise!.id));
    handleClose();
  };

  const handleClose = () => {
    setOffer(null);
    close();
  };

  return (
    <>
      <BoButton
        onClick={open}
        variant="secondary"
        size="extraSmall"
        icon={Extension}
      >
        Contract Swap - Snapshot
      </BoButton>
      <BoModal open={isOpen} onClose={handleClose}>
        {offerSnapshot ? (
          <ConfirmSwapContent
            offerSnapshot={offerSnapshot}
            onClose={handleClose}
            onSubmit={onConfirmSubmit}
            confirmDisabled={!!requestMonitor.isPending}
          />
        ) : (
          <SearchContent onSubmit={onSearchSubmit} onClose={handleClose} />
        )}
      </BoModal>
    </>
  );
};
