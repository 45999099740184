import { PriceScheduleBreakdownLabels } from "@common/components/PriceScheduleBreakdown/PriceScheduleBreakdown";
import { PriceScheduleBreakdownDateRange } from "@common/components/PriceScheduleBreakdown/PriceScheduleBreakdownDateRange/PriceScheduleBreakdownDateRange";
import { StyledSelect } from "@common/components/PriceScheduleBreakdown/PriceScheduleBreakdownDateRangeList/PriceScheduleBreakdownDateRangeList.styled";
import { PriceScheduleBreakdownVisualType } from "@common/types/priceScheduleBreakdownVisualTypes";
import { RhTabs } from "@design-system/components/RhTabs/RhTabs";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

type PriceScheduleBreakdownDateRangeListProps = {
  generationPriceScheduleBreakdowns: PriceScheduleBreakdownVisualType[] | null;
  isTouGeneration?: boolean;
  labels: PriceScheduleBreakdownLabels;
  priceScheduleBreakdowns: PriceScheduleBreakdownVisualType[];
};

export const PriceScheduleBreakdownDateRangeList = ({
  generationPriceScheduleBreakdowns,
  isTouGeneration,
  labels,
  priceScheduleBreakdowns,
}: PriceScheduleBreakdownDateRangeListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const dateRanges = priceScheduleBreakdowns.map((consumptionBreakdown) => {
    const generationBreakdown = generationPriceScheduleBreakdowns?.find(
      (gBreakdown) => gBreakdown.dateRange === consumptionBreakdown.dateRange
    );

    return {
      component: (
        <PriceScheduleBreakdownDateRange
          key={consumptionBreakdown.dateRange}
          priceScheduleBreakdown={consumptionBreakdown}
          generationPriceScheduleBreakdown={generationBreakdown}
          labels={labels}
          isTouGeneration={isTouGeneration}
        />
      ),
      title: consumptionBreakdown.dateRange,
    };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(0);

  if (!dateRanges) {
    return null;
  }

  if (dateRanges.length === 1) {
    return dateRanges[0].component;
  }

  if (isMobile) {
    return (
      <>
        <StyledSelect
          value={selectedDateRange}
          onChange={(e) => setSelectedDateRange(Number(e.target.value))}
        >
          {dateRanges.map((tab, index) => (
            <option key={tab.title} value={index}>
              {tab.title}
            </option>
          ))}
        </StyledSelect>
        {dateRanges[selectedDateRange].component}
      </>
    );
  }

  return (
    <div>
      <RhTabs tabPanelId="breakdowntabs" tabs={dateRanges} />
    </div>
  );
};
