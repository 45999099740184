import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { rhapsodyOfferSnapshotsApi } from "@ops-data/api/rhapsodyOfferSnapshotsApi";
import { OfferSnapshotQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseOfferSnapshotQueryProps {
  offerSnapshotId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useOfferSnapshotQuery = (props: UseOfferSnapshotQueryProps) => {
  const { offerSnapshotId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyOfferSnapshotsApi.retrieve(offerSnapshotId),
    queryKey: OfferSnapshotQueryKeys.retrieve(offerSnapshotId),
    select: (data) => new OfferSnapshot(data),
  });
};
