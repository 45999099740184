import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { Premise } from "@common/models/Premise.model";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import {
  AutoPayWarningText,
  ConfirmationDetailsColumn,
  ConfirmationDetailsContainer,
  ConfirmationDetailsSectionContainer,
  ConfirmationDetailsSectionLabel,
  ConfirmationDetailsSectionValue,
  PaperlessWarningText,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardConfirmationStep/ContractRenewalWizardConfirmationStep.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import dayjs from "dayjs";
import React, { PropsWithChildren, useId } from "react";

interface ConfirmationDetailsSectionProps {
  inline?: boolean;
  label: string;
}
const ConfirmationDetailsSection = (
  props: PropsWithChildren<ConfirmationDetailsSectionProps>
) => {
  const { label, children, inline = false } = props;

  const sectionId = useId();

  return (
    <ConfirmationDetailsSectionContainer $inline={inline}>
      <ConfirmationDetailsSectionLabel $inline={inline} id={sectionId}>
        {label}
      </ConfirmationDetailsSectionLabel>
      <ConfirmationDetailsSectionValue aria-labelledby={sectionId}>
        {children}
      </ConfirmationDetailsSectionValue>
    </ConfirmationDetailsSectionContainer>
  );
};

const OfferSnapshotDetails = ({
  offerSnapshot,
}: {
  offerSnapshot: OfferSnapshot;
}) => {
  return (
    <>
      <span>Plan: {offerSnapshot.title}</span>
      <span>
        Energy Rate:{" "}
        {offerSnapshot.isTimeOfUse ? (
          <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
        ) : (
          formatEnergyRate(offerSnapshot.rhythmKwhRate)
        )}
      </span>
      {offerSnapshot.solarEligible && !offerSnapshot.isTimeOfUseGeneration ? (
        <span>
          Buyback Rate: {formatEnergyRate(offerSnapshot.solarCreditKwhRate)}
        </span>
      ) : null}
      <span>
        Avg Price (2000kWh): {formatEnergyRate(offerSnapshot.price2000Kwh)}
      </span>
      <span>{offerSnapshot.termAndRate()}</span>
    </>
  );
};

export const ContractRenewalWizardConfirmationDetails = ({
  premise,
  currentOrderOfferSnapshot,
  renewalOfferSnapshot,
  paperlessEnabled,
}: {
  currentOrderOfferSnapshot: OfferSnapshot;
  paperlessEnabled?: boolean;
  premise: Premise;
  renewalOfferSnapshot: OfferSnapshot;
}) => {
  const mailingAddress = premise.mailingAddress ?? premise.serviceAddress;

  const showPaperlessWarning =
    renewalOfferSnapshot.isAutoPayPaperlessDiscountOffer && !paperlessEnabled;

  const showAutoPayWarning =
    !premise.autopay && renewalOfferSnapshot.isAutoPayPaperlessDiscountOffer;

  return (
    <ConfirmationDetailsContainer>
      <ConfirmationDetailsColumn>
        <ConfirmationDetailsSection label="Address">
          <span>{premise?.serviceAddress?.line1}</span>
          <span>{premise?.serviceAddress?.line2}</span>
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="Mailing Address">
          <span>{mailingAddress?.line1}</span>
          <span>{mailingAddress?.line2}</span>
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="ESI ID">
          {stringOrEmDash(premise.meter?.esiId)}
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="Utility">
          {stringOrEmDash(premise.meter?.utilityName)}
        </ConfirmationDetailsSection>
      </ConfirmationDetailsColumn>

      <ConfirmationDetailsColumn>
        <ConfirmationDetailsSection label="Current Plan">
          <OfferSnapshotDetails offerSnapshot={currentOrderOfferSnapshot} />
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="Start Date" inline>
          {formatMonthDayYear(premise?.currentOrder?.startDate)}
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="End Date" inline>
          {formatMonthDayYear(premise?.currentOrder?.contractEndDate)}
        </ConfirmationDetailsSection>
      </ConfirmationDetailsColumn>

      <ConfirmationDetailsColumn>
        <ConfirmationDetailsSection label="Renewal Plan">
          <OfferSnapshotDetails offerSnapshot={renewalOfferSnapshot} />
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="Start Date" inline>
          {formatMonthDayYear(premise?.currentOrder?.renewalStartDate)}
        </ConfirmationDetailsSection>

        <ConfirmationDetailsSection label="End Date" inline>
          {formatMonthDayYear(
            dayjs(premise.currentOrder?.renewalStartDate)
              .add(renewalOfferSnapshot.termMonths, "months")
              .toString()
          )}
        </ConfirmationDetailsSection>
      </ConfirmationDetailsColumn>

      <ConfirmationDetailsColumn>
        <ConfirmationDetailsSection label="Paperless" inline>
          {onOff(paperlessEnabled)}
        </ConfirmationDetailsSection>
        {showPaperlessWarning ? (
          <PaperlessWarningText>
            Auto Pay and Paperless must be turned on for the Auto Pay and
            Paperless credit.
          </PaperlessWarningText>
        ) : null}

        <ConfirmationDetailsSection label="Auto Pay" inline>
          {onOff(premise.autopay)}
        </ConfirmationDetailsSection>

        {showAutoPayWarning ? (
          <AutoPayWarningText>
            Auto Pay and Paperless must be turned on for the Auto Pay and
            Paperless credit.
          </AutoPayWarningText>
        ) : null}
      </ConfirmationDetailsColumn>
    </ConfirmationDetailsContainer>
  );
};
