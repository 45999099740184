import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { RhApiError } from "@common/types/errorTypes";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { CustomerErrorCodes } from "@ops/constants/errorCodes.constants";
import { customerRefetch } from "@ops/slices/customerSlice";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditEmailProps {
  customer: Customer;
}

interface EditEmailFormValues {
  email: string;
}

const editEmailFormValidators =
  generateValidationErrorCollector<EditEmailFormValues>({
    email: [isRequired, isValidEmail],
  });

const EmailDetailsContainer = styled.div`
  ${editDialogContentsCss}
`;

export const EditEmail = ({ customer }: EditEmailProps) => {
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  const handleSubmit = (formValues: EditEmailFormValues) => {
    setPending();

    return api.customers
      .update(customer.id, formValues)
      .then(() => {
        setSuccess();
        dispatch(customerRefetch(customer.id));
        close();
      })
      .catch((error: RhApiError) => {
        setFailure();
        return { email: generateErrorMessage(error.data.errorCode as string) };
      });
  };

  const generateErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case CustomerErrorCodes.EMAIL_UNIQUE: {
        return "Customer with this email already exists.";
      }
      case CustomerErrorCodes.FIELDS_INVALID: {
        return "Email provided is invalid. Please enter a valid email address.";
      }
      default: {
        return "Something went wrong. Please try again later.";
      }
    }
  };

  return (
    <>
      <EditLink onClick={open} />

      <BoModal open={isOpen} onClose={close}>
        <Form<EditEmailFormValues>
          validate={editEmailFormValidators}
          onSubmit={handleSubmit}
          initialValues={{ email: customer.email }}
          render={({
            handleSubmit: handleFormSubmit,
            dirty,
            hasValidationErrors,
          }) => (
            <form noValidate onSubmit={handleFormSubmit}>
              <BoDialogHeader>Edit Email Address</BoDialogHeader>
              <BoDialogBody>
                <EmailDetailsContainer>
                  <Subtitle2 as="div">
                    Customer&apos;s current email address:
                  </Subtitle2>
                  <Subtitle2 $fontWeight="Bold" as="div">
                    {customer.email}
                  </Subtitle2>
                </EmailDetailsContainer>
                <BoTextField name="email" placeholder="Email">
                  Email
                </BoTextField>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmDisabled={!dirty || hasValidationErrors}
                confirmLoading={requestMonitor.isPending}
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Close"
                onCancel={close}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
