import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { LANGUAGE_PREFERENCE_TEXT } from "@ops/constants/customer.constants";
import { customerRefetch } from "@ops/slices/customerSlice";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditLanguageFormValues {
  language: SupportedPortalLanguage;
}

interface EditLanguagePreferenceProps {
  customer: Customer;
}

const StyledLanguageWrapper = styled.div`
  ${editDialogContentsCss}
`;

const StyledSelectWrapper = styled.div`
  max-width: 50%;
`;

export const EditLanguagePreference = ({
  customer,
}: EditLanguagePreferenceProps) => {
  const fieldName = "language";
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  const options: BoSelectOptionType[] = Object.entries(
    LANGUAGE_PREFERENCE_TEXT
  ).map(([code, name]) => ({
    label: name,
    value: code,
  }));

  const handleSubmit = ({ language }: EditLanguageFormValues) => {
    setPending();

    return api.customers
      .update(customer.id, {
        languagePreference: language,
      })
      .then(() => {
        dispatch(customerRefetch(customer.id));
        setSuccess();
        close();
      })
      .catch(() => {
        setFailure();

        return {
          [fieldName]:
            "Unable to update the language preference. Please try again later.",
        };
      });
  };

  return (
    <>
      <EditLink onClick={open} />
      <BoModal open={isOpen} onClose={close}>
        <Form<EditLanguageFormValues>
          initialValues={{
            language: customer.languagePreference,
          }}
          onSubmit={handleSubmit}
          validateOnBlur
          render={({ dirty, handleSubmit: formHandleSubmit }) => (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Edit Language Preference</BoDialogHeader>
              <BoDialogBody>
                <StyledLanguageWrapper>
                  <Subtitle2>
                    Customer&apos;s current language preference is
                  </Subtitle2>{" "}
                  <Subtitle2 $fontWeight="Bold">
                    {LANGUAGE_PREFERENCE_TEXT[customer.languagePreference]}
                  </Subtitle2>
                </StyledLanguageWrapper>
                <StyledSelectWrapper>
                  <BoSelectField
                    label="Preferred Language"
                    placeholder="Choose language..."
                    name={fieldName}
                    defaultValue={customer.languagePreference}
                    options={options}
                  />
                </StyledSelectWrapper>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={close}
                confirmDisabled={!dirty || requestMonitor.isPending}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
